<div *ngIf="!datiModelloDocumentale">
  <p>{{message}}</p>
</div>

<div class="w-100 d-flex size-form-group pt-3" style="align-items: flex-start;">

  <div class="w-50">
    <form *ngIf="datiModelloDocumentale?.formGroup" [formGroup]="datiModelloDocumentale?.formGroup">
      <!--Campi da Visualizzare-->

      <div *ngIf="header" class="mb-4 d-flex">
        <span style="font-weight:600; font-size: 20px;">{{header}}</span>
      </div>

      <div class="row">
        <div class="row mb-4">

          <div *ngFor="let item of datiModelloDocumentale?.DatiForm; let i=index"
            class="form-group col-md-{{item.dimensioneBasic}}"
            [ngClass]="{'hidden' : item.tipo === 'hidden' || !item.visible}">


            <!-- BOTTONE -->
            <button *ngIf="item.visible && item.tipo === 'button'" type="button" pButton pRipple
              icon="pi pi-{{item.icon}}" style="height: 2.25em;" (click)="btnClick(item)"
              class="p-button-{{item.classColor}} mt-3" pTooltip="{{item.pTooltip}}"
              tooltipPosition="{{item.tooltipPosition}}">{{item.btnLabel}}</button>

            <!-- LABEL -->
            <label *ngIf="item.visible && item.tipo !== 'allegato' && item.tipo !== 'hidden'" for="{{item.key}}"
              class="col-form-label">{{item.label}}</label>

            <!-- AUTOCOMPLETE -->
            <p-autoComplete *ngIf="item.visible && item.tipo === 'autocomplete'" [dropdown]="true"
              [suggestions]="item.lista" formControlName="{{item.key}}" field="descrizione"
              emptySelectionMessage="Non sono presenti elementi" (completeMethod)="onFilterAutoComplite($event, item)"
              (onSelect)="dropdownChange(item)"></p-autoComplete>

            <input *ngIf="item.visible && item.tipo === 'text' && !item.hasAction" formControlName="{{item.key}}"
              type="text" class="form-control" [ngClass]="item.readonly?'disabled':''"
              [readonly]="item.readonly ? item.readonly : false">

            <input *ngIf="item.visible && item.tipo === 'text' && item.hasAction" formControlName="{{item.key}}"
              type="text" class="form-control" [ngClass]="item.readonly?'disabled':''"
              [readonly]="item.readonly? item.readonly:false" oninput="this.value = this.value.toUpperCase()">

            <textarea *ngIf="item.visible && item.tipo === 'textarea'" formControlName="{{item.key}}" type="text"
              class="form-control inputScadenzeForm" pInputTextarea [autoResize]="true" (change)="textareaChange(item)">
                  </textarea>

            <p-inputNumber *ngIf="item.visible && item.tipo === 'number'" formControlName="{{item.key}}"
              inputId="{{item.key}}" class="form-control numb" (onFocus)="focusImporto($event)">
            </p-inputNumber>

            <!-- INPUT DEGLI IMPORTI -->
            <p-inputNumber *ngIf="item.visible && item.tipo === 'currency'" formControlName="{{item.key}}"
              mode="currency" inputId="{{item.key}}" class="form-control numb" currency="EUR" locale="it-IT"
              [readonly]="item.readonly? item.readonly:false" (ngModelChange)="currencyChange(item)"
              (onFocus)="focusImporto($event)">
            </p-inputNumber>

            <!-- INPUT DEI RIFERIMENTI / TIPOLOGIE / TIPOLOGIE DOCUMENTO!!! -->
            <p-dropdown *ngIf="item.visible && item.tipo === 'lista'" class="padding-span" [options]="item.lista"
              formControlName="{{item.key}}" optionValue="{{item.optionValue}}" optionLabel="{{item.optionLabel}}"
              placeholder="{{item.placeholder}}" [ngClass]="item.readonly ? 'disabled' : '' " 
              (onChange)="dropdownChange(item)" appendTo="body">
            </p-dropdown>

            <!-- Radio -->
            <div *ngIf="item.visible && item.tipo === 'radio'" class="flex align-items-start">
              <span *ngFor="let radio of item?.lista" class="flex align-items-start mr-1">
                <p-radioButton formControlName="{{item.key}}" (click)="radioChange(item)" [value]="radio.descrizione"
                  [label]="radio.descrizione" [inputId]="radio.descrizione" class="{{item.classeCss}}"></p-radioButton>
              </span>
            </div>

            <!-- Data -->
            <p-calendar *ngIf="item.visible && item.tipo === 'date'" formControlName="{{item.key}}"
              dateFormat="dd/mm/yy" appendTo="body" class="form-control numb" dateMask (onFocus)="focusImporto($event)"
              [locale]="CALENDER_CONFIG_IT">
            </p-calendar>

            <small *ngIf="checkIsTouched(item?.key)" style="color:#dc3545;">{{item.errorMessage}}</small>
          </div>
        </div>

      </div>
    </form>
  </div>

  <!-- Anteprima visibile-->
  <div *ngIf="datiModelloDocumentale?.formGroup" class="w-50">

    <!--Lista documenti-->
    <div *ngIf="!showDocumentCM">
      <div class="col-md-12 d-flex mb-2">

        <!--Cartella Monitorata-->
        <button *ngIf="datiModelloDocumentale?.formGroup?.enabled" type="button" pButton pRipple icon="pi pi-upload"
          style="width: 2rem; background-color: #35b6c7; border-color: #35b6c7" (click)="showDocumentiCM()" class="mr-2"
          pTooltip="Documenti archiviati" tooltipPosition="bottom"></button>

        <!--Aggiungi file-->
        <p-fileUpload *ngIf="datiModelloDocumentale?.formGroup?.enabled" #fileUploader name="listaFileTemporanea[]"
          url="https://www.primefaces.org/cdn/api/upload.php" [chooseLabel]="'Aggiungi File'" [showUploadButton]="false"
          [showCancelButton]="false" class="w-100 ml-1" [customUpload]="true"
          (onSelect)="previewFile($event.files[0], $event)" [multiple]="true">
          <ng-template let-file pTemplate='file' let-index='index'>
            <div class="d-flex justify-content-between align-items-center mb-2">
              <div class="d-flex justify-content-start align-items-center w-75">
                <span class="align-items-center">
                  <p-button type="button" icon="pi pi-pencil" title="Rinomina File"
                    (click)="indexRinominafileAdd = index" styleClass="p-button-text p-button-secondary"></p-button>
                </span>
                <span class="d-flex justify-content-start align-items-center" *ngIf="indexRinominafileAdd !== index"
                  style="cursor: pointer;" (click)="previewFile(file)">
                  <span style="padding-left: 10px;">{{file.name | fileNamePipe}}</span>
                  <span>.{{getExtensioneFile(file.name)}}</span>
                </span>

                <span class="d-flex justify-content-start align-items-center" *ngIf="indexRinominafileAdd === index">
                  <input pInputText type="text" [ngModel]="file.name | fileNamePipe" class="input-custom-file"
                    (ngModelChange)="changedName=$event" />
                  <span>.{{getExtensioneFile(file.name)}}</span>
                  <p-button type="button" icon="pi pi-check" title="Rinomina File" (click)="renameFile(index)"
                    styleClass="p-button-text p-button-secondary">
                  </p-button>
                  <p-button type="button" icon="pi pi-times" title="Annulla Rinomina File"
                    (click)="annullaRename(index)" styleClass="p-button-text p-button-secondary">
                  </p-button>
                </span>
                <!--Dimensione file-->
                <span>
                  {{file.size | formatFileSize:false }}
                </span>
              </div>

              <span class="d-flex justify-content-end w-25">
                <!--Cancellazione file-->
                <p-button type="button" icon="pi pi-trash" title="Cancella File"
                  (click)="removeFile(file, fileUploader)" styleClass="p-button-text p-button-danger"></p-button>
              </span>
            </div>
          </ng-template>
        </p-fileUpload>
      </div>

      <!--Lista dei file caricati-->
      <div *ngIf="listFiles?.length > 0" class="size-dialog-file">
        <div *ngFor="let file of listFiles; let index = index;">

          <div class="d-flex justify-content-between align-items-center mb-2 border">

            <div class="d-flex justify-content-start align-items-center w-75">
              <!--Matita Rinomina File-->
              <span class="align-items-center"
                *ngIf="datiModelloDocumentale?.formGroup?.enabled && indexRinominafile !== index">
                <p-button type="button" icon="pi pi-pencil" title="Rinomina File" (click)="indexRinominafile = index"
                  styleClass="p-button-text p-button-secondary"></p-button>
              </span>
              <!--Nome File + Estensione-->
              <span class="d-flex justify-content-start align-items-center" *ngIf="indexRinominafile !== index"
                style="cursor: pointer;" (click)="loadFileFromServer(file.id)">
                <span class="pl-1">{{file.nomeFile | fileNamePipe}}</span>
                <span>.{{getExtensioneFile(file.nomeFile)}}</span>
              </span>

              <span class="d-flex justify-content-start align-items-center" *ngIf="indexRinominafile === index">
                <!--Campo per Rinomina file-->
                <input pInputText type="text" [ngModel]="file.nomeFile | fileNamePipe" class="input-custom-file"
                  (ngModelChange)="changedName=$event" />
                <span>.{{getExtensioneFile(file.nomeFile)}}</span>
                <!--Conferma Rinomina File-->
                <p-button type="button" icon="pi pi-check" title="Rinomina File" (click)="renameFileLista(index)"
                  styleClass="p-button-text p-button-secondary">
                </p-button>
                <!--Annulla Rinomina File-->
                <p-button type="button" icon="pi pi-times" title="Annulla Rinomina File"
                  (click)="annullaRenameLista(index)" styleClass="p-button-text p-button-secondary">
                </p-button>
              </span>
              <!--Dimensione file-->
              <span *ngIf="file?.file?.size">
                {{ file?.file?.size | formatFileSize:false }}
              </span>
            </div>

            <span class="d-flex justify-content-end w-25">
              <!--Download file-->
              <button pButton pRipple type="button" icon="pi pi-download" (click)="downloadFile(file.id, file.nomeFile)"
                class="p-button-rounded p-button-text" pTooltip="Scarica file">
              </button>
              <!--Cancellazione file-->
              <p-button type="button" *ngIf="datiModelloDocumentale?.formGroup?.enabled" icon="pi pi-trash"
                (click)="showDialogDeleteFile(file.id)" styleClass="p-button-text p-button-danger">
              </p-button>
            </span>
          </div>
        </div>
      </div>
    </div>

    <!--Seleziona file cartella monitorata-->
    <div *ngIf="showDocumentCM">

      <div class="mb-1 d-flex justify-content-start">
        <button type="button" pButton pRipple icon="pi pi-arrow-left" (click)="showDocumentCM = false" class="mr-1"
          pTooltip="Torna alla schermata precedente" tooltipPosition="bottom"></button>

        <div>
          <h5>Includi Senza Cup</h5>
          <p-inputSwitch [(ngModel)]="includiSenzaCup" (onChange)="showDocumentiCM()"></p-inputSwitch>
        </div>
      </div>

      <div *ngIf="!caricamentoGrid" style="min-height: 50vh; overflow: scroll;">
        <p-table [value]="documentBrowse" [paginator]="true" [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10, 25, 50]" [(first)]="first" [(rows)]="rows" (onPage)="pageChange($event)"
          [(selection)]="selectedDocumento" dataKey="codice"
          currentPageReportTemplate="Da {first} a {last} di {totalRecords} Documenti">
          <ng-template pTemplate="header">
            <tr>
              <th>Data</th>
              <th>Ora</th>
              <th>Nome file</th>
              <th>Seleziona</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-documento>
            <tr class="riga-documenti" [pSelectableRow]="documento">
              <td>{{ documento?.uploadAt | date }}</td>
              <td>{{ documento?.uploadAt | date:'h:mm' }}</td>
              <td>{{ documento?.nomeFile }}</td>
              <td>
                <p-button type="button" icon="pi pi-download" styleClass="p-button-text"
                  (click)="prendiInCaricoDocumento(documento)"></p-button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <!-- Spiner caricamento Lista documenti/preview documenti-->
    <div *ngIf="caricamentoFileInCorso || caricamentoGrid" class="text-center">
      <p-progressSpinner></p-progressSpinner>
      <div> Caricamento in corso </div>
    </div>

    <!------------------------------ANTEPRIMA DEL FILE------------------------------>
    <div *ngIf="!caricamentoFileInCorso && !showDocumentCM">
      <div *ngIf="pdfSrc">

        <h1 *ngIf="nomeFileAnteprimaDocumento" for="allegato" class="col-form-label">
          {{nomeFileAnteprimaDocumento}}
        </h1>

        <div *ngIf="isPdf; else imagePreview" class="doc-dimension">
          <div class="d-flex justify-content-between">
            <div class="w-25">
              <input #queryInp id="pdfQueryInput" pInputText type="text" [(ngModel)]="pdfQuery" placeholder="Cerca..."
                class="input-custom-file" (input)="searchQueryChanged($event)"
                (keyup.enter)="searchQueryChanged(queryInp)" />
            </div>
            <div class="w-50">
              <div class="d-flex justify-content-center">
                <p-button type="button" icon="pi pi-plus-circle" (click)="plusZoom()"
                  styleClass="p-button-text p-button-info"></p-button>
                <p-button *ngIf="zoom > 1" type="button" icon="pi pi-minus-circle" (click)="minusZoom()"
                  styleClass="p-button-text p-button-info"></p-button>
                <p-button type="button" icon="pi pi-refresh" (click)="rotateDoc()"
                  styleClass="p-button-text p-button-info"></p-button>
                <p-button type="button" icon="pi pi-print" (click)="print()"
                  styleClass="p-button-text p-button-info"></p-button>
                <p-button type="button" icon="pi pi-eye" title="Anteprima File in nuova pagina"
                  styleClass="p-button-text p-button-info" (click)="previewFilePage()"></p-button>
              </div>
            </div>
            <div class="w-25">Numero Pagine: {{totalPages}}</div>
          </div>
          <!-- Preview Pdf-->
          <pdf-viewer [src]="pdfSrc" [render-text]="renderText" [render-text-mode]="renderTextMode"
            [rotation]="rotation" [zoom]="zoom" [original-size]="originalSize" [fit-to-page]="fitToPage"
            [show-all]="true" [autoresize]="autoresize" [show-borders]="false"
            (after-load-complete)="callBackFn($event)" (page-rendered)="pageRendered($event)"
            (text-layer-rendered)="textLayerRendered($event)" (error)="onError($event)"
            (on-progress)="onProgress($event)" style="width:100%; min-height:50vh; display:block">
          </pdf-viewer>
        </div>

        <!-- Preview Immagine-->
        <ng-template #imagePreview>
          <div *ngIf="pdfSrc" class="preview-container">
            <img [src]="pdfSrc" class="image-dim" alt="Anteprima non disponibile" />
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>

<div *ngIf="visualizza && qe && datiModelloDocumentale?.formGroup" class="p-dialog-footer">
  <!--Pubblica-->
  <button pButton [label]="!isDraft ? 'Inserisci dati pubblicazione' : 'Pubblica'" class="mr-1 p-button"
    [disabled]="!abilitaPubblica || isDraft && !(totaleQE && datiModelloDocumentale?.formGroup?.valid)"
    (click)="isPublishing()"></button>
</div>

<!--Bottoni per salvataggi e varie-->
<div *ngIf="visualizza && datiModelloDocumentale?.formGroup" class="fixed-bottom">

  <div [ngClass]="isScenarioCup ? 'container' : 'mr-2'" class="d-flex justify-content-end mb-3">

    <!--ANNULLA-->
    <button *ngIf="buttonAnnulla && showSecondButton" pButton label="ANNULLA" class="mr-1 p-button-secondary"
      (click)="onClose(false)"></button>

    <!--label-->
    <button *ngIf="datiModelloDocumentale?.formGroup?.enabled && !datiModelloDocumentale?.isEdit && 
      datiModelloDocumentale?.isFrom !== 12 && !buttonDocumenti" pButton [label]="label" class="mr-1 p-button"
      [disabled]="datiModelloDocumentale?.formGroup?.invalid" (click)="onAdd()"></button>

    <!--SALVA-->
    <button *ngIf="datiModelloDocumentale?.formGroup?.enabled && 
      ((datiModelloDocumentale?.isEdit && datiModelloDocumentale?.isFrom !== 8 && datiModelloDocumentale?.isFrom !== 11 && datiModelloDocumentale?.isFrom !== 12))
      && (!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator))"
       pButton label="SALVA" class="mr-1 p-button-success" [disabled]="datiModelloDocumentale?.formGroup?.invalid" (click)="onSave()"></button>

    <!--SALVA FILE-->
    <button *ngIf="buttonDocumenti || (buttonDocumenti && (datiModelloDocumentale?.isFrom === 8 && nomeModelliDocumenti.includes(nomeModello))) 
      && (!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator))" pButton label="SALVA FILE" class="mr-1 p-button-success" 
      [disabled]="!(datiModelloDocumentale?.formGroup?.valid && isEnabledLoadFile)" (click)="onSave()"></button>

    <!--MODIFICA -->
    <button *ngIf="!datiModelloDocumentale?.formGroup?.enabled && ((datiModelloDocumentale?.isEdit && datiModelloDocumentale?.isFrom !== 8) ||
      datiModelloDocumentale?.isFrom === 8 && !hasSystemModello) && (!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator))" pButton label="MODIFICA"
      [disabled]="datiModelloDocumentale?.formGroup?.invalid" icon="pi pi-pencil" class="mr-1 p-button"
      (click)="modifica()"></button>

    <!--ELIMINA CUP-->
    <button *ngIf="datiModelloDocumentale?.formGroup?.enabled && showSecondButton && buttonCancella
      && (!permesso || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator))" pButton
      label="ELIMINA CUP" class="mr-1 p-button-danger" (click)="onDelete()"></button>


  </div>
</div>

<rbk-modal *ngIf="visibleDialog" [dialogModel]="dialogModel" (onConfirm)="onConfirmPopup()" (onCancel)="onClosePopup()"></rbk-modal>