import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { tipiSpese } from '../../model/spesa';
import { SpeseService } from 'src/app/service/spese.service';
import {
  ConfirmationService,
  MessageService,
  PrimeNGConfig,
} from 'primeng/api';
import {
  Fase1,
  Fase1Response,
  Fase2,
  Fase2Base,
  Fase2Response,
  Fase2Riferimenti,
  Fase3,
  Fase3Base,
  Fase3Response,
  Fase3Submit,
  Fase4,
  Fase4Base,
  Fase4Response,
  Fase5,
  Fase5Response,
  Fasi1Response,
} from 'src/app/model/fase1';
import { NgxSpinnerService } from 'ngx-spinner';
import { QE, QEItemBase } from 'src/app/model/qe';
import { QEService } from 'src/app/service/qe.service';
import { CUP } from 'src/app/model/cup';
import { EsportaExcelTipo } from 'src/app/service/export-excel.service';
import { SharedService } from 'src/app/service/shared.service';
import { Dizionario, ModelloDocumentaleCustom, Scenario, TipologiaDizionario } from 'src/app/model/scenari';
import { ScenarioService } from 'src/app/service/scenario.service';
import { Ruolo, TipoPermesso, Utente } from 'src/app/model/utente';
import { RuoloPipe } from 'src/app/pipe/ruolo-pipe';

@Component({
  selector: 'app-cup-esecuzione',
  templateUrl: './cup-esecuzione.component.html',
  styleUrls: ['./cup-esecuzione.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class CupEsecuzioneComponent {
  @Input() cup: CUP;
  @Input() qe: QE;
  @Input() isEsecuzioneEnable: boolean;
  @Input() idCup: number;
  /**
   * Permesso in base all'utente loggato
   */
  @Input() public permesso: TipoPermesso;
  /**
   * Utente loggato
   */
  @Input() public user: Utente;

  @Output() sendRowImpegnate = new EventEmitter<QEItemBase[]>();

  public beneficiarioString: string;

  public pIva_cFiscaleString: string;

  esportaExcelTipo = EsportaExcelTipo;

  datiModelloDocumentale: ModelloDocumentaleCustom;
  giustificativi: Fase2Base[];
  liquidazioni: Fase3Base[];
  mandati: Fase4Base[];
  visibleFormSpesa: boolean;
  visibleFormGiustificativo: boolean;
  visibleFormLiquidazione: boolean;
  visibleFormMandato: boolean;
  visibleFormQuietanza: boolean;
  visualizzaForm: boolean;
  stopFase5: boolean;

  // Id dell'elemento appena creato da inviare all'onchange per procedere con il salvataggio del documento se collegato
  entityIdAdd: number;

  spesa: Fase1;
  spese: Fase1[];
  quietanza: Fase5;
  fase2Riferimenti: Fase2Riferimenti;
  qeFlatItems: QEItemBase[];
  rowQEImpegnate: QEItemBase[] = [];
  filteredTab: Fase1[];
  selectedSpesa: Fase1;
  tipoSpeseForm: FormGroup;
  rif: Fase4;

  totaleFatture: number = 0;
  isId: boolean;
  id: number;
  spesa_id: number;
  isInEdit: boolean = false;
  visualizza: boolean;
  showMessages: boolean;
  selected_Riferimento: string;

  calcolaTotale: number;
  calcolaTotaleL: number;
  calcolaTotaleM: number;
  calcolaTotaleQ: number;

  tipiSpese: tipiSpese[] = [
    { tipo: 'Tutti' },
    { tipo: 'Aggiudicazione' },
    { tipo: 'Impegno' },
    { tipo: 'Affidamento' },
    { tipo: 'Impegno/Liquidazione' },
  ];

  public tipiSpeseFiltro: Dizionario[];
  public tipiSpeseFromDizionario: Dizionario[];
  public tipiGiustificativiFromDizionario: Dizionario[];
  public tipiAttiFromDizionario: Dizionario[];
  public TipoPermesso = TipoPermesso;
	public Ruolo = Ruolo;
	public RuoloPipe = new RuoloPipe();

  beneficiari = [];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private speseServices: SpeseService,
    private qeService: QEService,
    private spinner: NgxSpinnerService,
    private primengConfig: PrimeNGConfig,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private sharedService: SharedService,
    private cdRef: ChangeDetectorRef,
    private scenarioServices: ScenarioService
  ) {  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  async ngOnChanges(change: SimpleChanges) {
    const isEsecuzioneEnableChanges = change && change['isEsecuzioneEnable'];
    if (isEsecuzioneEnableChanges && isEsecuzioneEnableChanges.currentValue) {
      await this.getQeFlatItems();
    }
  }

  public async getQeFlatItems() {
    const response = await this.qeService.getFlatQEItems(this.cup?.id).toPromise();
    if (response && response.success) {
      this.qeFlatItems = response.dtos || [];
    } else {
      console.log('500');
    }
  }

  async ngOnInit() {
    this.primengConfig.ripple = true;
    this.spese = [];
    this.qeFlatItems = [];
    this.isId = this.route.snapshot.paramMap.get('id') ? true : false;
    this.tipoSpeseForm = this.fb.group({ tipo: new FormControl(1) });
    if (this.isId) {
      const fasiCaricateConSuccesso = await this.onUpdateFasi();
      this.tipiSpeseFiltro = [new Dizionario(TipologiaDizionario.Spesa_Tipo)];
      this.tipiSpeseFromDizionario = await this.sharedService.getDizionarioByCategoria(TipologiaDizionario.Spesa_Tipo);
      this.tipiAttiFromDizionario = await this.sharedService.getDizionarioByCategoria(TipologiaDizionario.Atto);
      this.tipiGiustificativiFromDizionario = await this.sharedService.getDizionarioByCategoria(TipologiaDizionario.Giustificativo_Tipo);
      this.tipiSpeseFiltro = [...this.tipiSpeseFromDizionario];
      if(fasiCaricateConSuccesso){
        this.openDialogNavigazioneDaDocumenti(this.filteredTab);
        await this.getQeFlatItems();
      }      
    } else {
      this.idCup = 0;
    }
  }

  /**
   * Carica documento da Cartella Monitorata
   */
  openDialogNavigazioneDaDocumenti(listaSpese: Fase1[]) {
    const dati = this.sharedService.getParametriNavigaDaDocumentiArchviazione();
    const idSpesa = dati?.idSottoSezione;
    const spesa = listaSpese?.find((spesa) => spesa.id === idSpesa);
    if (dati && dati?.sezione === 'Spesa') {
      switch (dati?.sottoSezione) {
        case 'Spesa':
          this.showDialogForm(false, true);
          break;
        case 'Giuistificativo':
          this.showDialogFatture(false, true, undefined, spesa, true);
          break;
        case 'Liquidazione':
          this.showDialogLiquidazioni(false, true, spesa, undefined, true);
          break;
        case 'Mandato':
          this.showDialogMandati(false, true, spesa, undefined, true);
          break;
        case 'Quitanza':
          this.showDialogQuietanze(false, spesa, undefined, true);
          break;
      }
    }
  }

  /**
   * Aggiunta di una nuova spesa, da unire con il salvataggio
   */
  async addSpesa(form) {
    this.spinner.show();
    let spesa = form.value;
    spesa.cupId = this.idCup;
    const resposne = await this.speseServices.submitFase1(spesa).toPromise();
    if (resposne && resposne.success) {
      this.entityIdAdd = resposne.dto.id;
      this.scenarioServices.showSuccessMessage('Spesa aggiunta con successo');
      await this.onUpdateFasi();
    } else {
      this.scenarioServices.showErrorMessage(
        "Errore durante l'aggiunta della Spesa"
      );
    }
    this.spinner.hide();
    this.visibleFormSpesa = false;
  }

  async saveSpesa(form) {
    let spesa = form.value;
    this.spinner.show();
    const response = await this.speseServices
      .updateFase1(spesa, spesa.id)
      .toPromise();
    if (response && response.success) {
      this.scenarioServices.showSuccessMessage('Spesa modificata con successo');
      await this.onUpdateFasi();
    } else {
      this.scenarioServices.showErrorMessage(
        'Errore durante la modifica della spesa'
      );
    }
    this.spinner.hide();
    this.visibleFormSpesa = false;
  }

  async addGiustificativo(form) {
    this.spinner.show();
    let giustificativo = form.value;
    const response = await this.speseServices.submitFase2(giustificativo).toPromise();
    if (response && response.success) {
      this.entityIdAdd = response.dto.id;
      let spesa = this.spese.find((x) => x.id === giustificativo.fase1Id);
      this.scenarioServices.showSuccessMessage('Giustificativo aggiunto con successo');
      if (spesa.tipo === 'Impegno/Liquidazione') {
        await this.createCustomFase3(response.dto);
      } else {
        await this.onUpdateFasi();
      }
      
    } else {
      console.log('500');
    }
    this.visibleFormGiustificativo = false;
    this.spinner.hide();
  }

  async createCustomFase3(giustificativo) {
    this.spinner.show();
    let fase3Submit: Fase3Submit = {
      id: 0,
      fase2Id: giustificativo.id,
      numero: giustificativo.numero,
      data: giustificativo.data,
      importo:
        giustificativo.importoNetto +
        giustificativo.iva +
        giustificativo?.cassa,
    };
    const response = await this.speseServices.submitFase3(fase3Submit).toPromise();
    if (response && response.success) {
      await this.onUpdateFasi();
    } else {
      console.log('500');
    }
  }

  async saveGiustificativo(form) {
    this.spinner.show();
    let giustificativo = form.value;
    const response = await this.speseServices.updateFase2(giustificativo, giustificativo.id).toPromise();
    if (response && response.success) {
      await this.onUpdateFasi();
      this.scenarioServices.showSuccessMessage('Giustificativo modificato con successo');
    } else {
      this.scenarioServices.showErrorMessage('Errore durante la modifica del giustificativo');
      console.log('500');
    }
    this.visibleFormGiustificativo = false;
    this.spinner.hide();
  }

  async addLiquidazione(form) {
    this.spinner.show();
    let liquidazione = form.value;
    const response = await this.speseServices.submitFase3(liquidazione).toPromise();
    if (response && response.success) {
      this.entityIdAdd = response.dto?.id;
      this.scenarioServices.showSuccessMessage('Liquidazione aggiunta con successo');
      await this.onUpdateFasi();
    } else {
      this.scenarioServices.showErrorMessage('Errore durante il salvataggio della liquidazione');
      console.log('500');
    }
    this.visibleFormLiquidazione = false;
    this.spinner.hide();
  }

  checkFase2(form) {
    let liquidazione = form.value;
    let x: number = 0;
    while (x < this.spesa.fasi3.length) {
      if (form.value.fase2Id === this.spesa.fasi3[x].fase2Id) {
        return this.confirmCheckGiustificativiImpegnati(form);
      }
      x++;
    }
    return this.addLiquidazione(form);
  }

  confirmCheckGiustificativiImpegnati(form) {
    this.confirmationService.confirm({
      message:
        'Questo giustificativo di spesa ha già una liquidazione associata. Procedere comunque?',
      header: 'Eliminazione',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, crea liquidazione',
      rejectLabel: 'No, torna indietro',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Liquidazione aggiunta con successo',
        });
        //this.visibleFormLiquidazione = false
        this.addLiquidazione(form);
      },
    });
  }

  async saveLiquidazione(form) {
    this.spinner.show();
    let liquidazione = form.value;
    const response = await this.speseServices.updateFase3(liquidazione, liquidazione.id).toPromise();
    if (response && response.success) {
      await this.onUpdateFasi();
      this.scenarioServices.showSuccessMessage('Liquidazione modificata con successo');
    } else {
      this.scenarioServices.showErrorMessage('Errore durante la modifica della liquidazione');
      console.log('500');
    }
    this.visibleFormLiquidazione = false;
    this.spinner.hide();
  }

  async addMandato(form) {
    this.spinner.show();
    const riferimentoFind = form.get('fase3Id').value;
    const fase2 = this.spesa.fasi2.find((fase2) => fase2.id === riferimentoFind);
    if (fase2) {
      form.get('fase3Id').setValue(fase2.fasi3[0].id);
    }
    let mandato = form.value;
    const response = await this.speseServices.submitFase4(mandato).toPromise();
    if (response && response.success) {
      this.entityIdAdd = response.dto?.id;
      this.scenarioServices.showSuccessMessage('Mandato aggiunto con successo');
      await this.onUpdateFasi();
    } else {
      this.scenarioServices.showErrorMessage('Errore durante il salvataggio del mandato');
      console.log('500');
    }
    this.visibleFormMandato = false;
    this.spinner.hide();
  }

  checkFase3(form) {
    let mandato = form.value;
    //this.fase4Submit = this.fase4Form.value;

    let x: number = 0;
    while (x < this.spesa.fasi4.length) {
      console.log(this.spesa.fasi3[x]);
      if (mandato.fase3Id === this.spesa.fasi4[x].fase3Id) {
        return this.confirmCheckLiquidazioniImpegnate(form);
      }
      x++;
    }
    return this.addMandato(form);
  }

  confirmCheckLiquidazioniImpegnate(form) {
    this.confirmationService.confirm({
      message:
        this.spesa.tipo !== 'Impegno/Liquidazione'
          ? 'Questa liquidazione ha già un mandato associato. Procedere comunque?'
          : 'Questo impegno/liquidazione ha già un mandato associato. Procedere comunque?',
      header: 'Eliminazione',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, crea mandato',
      rejectLabel: 'No, torna indietro',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Mandato aggiunto con successo',
          sticky: true,
        });
        //this.visibleFormMandato = false
        this.addMandato(form);
      },
    });
  }

  async saveMandato(form) {
    this.spinner.show();
    let mandato = form.value;
    const response = await this.speseServices.updateFase4(mandato, mandato.id).toPromise();
    if (response && response.success) {
      this.scenarioServices.showSuccessMessage('Mandato modificato con successo');
      await this.onUpdateFasi();
    } else {
      this.scenarioServices.showErrorMessage('Errore durante la modifica del mandato');
      console.log('500');
    }
    this.visibleFormMandato = false;
    this.spinner.hide();
  }

  async addQuietanza(form) {
    this.spinner.show();
    let quietanza = form.value;
    
    const response = await this.speseServices.submitFase5(quietanza).toPromise();
    if (response && response.success) {
      this.entityIdAdd = response.dtos?.id;
      this.scenarioServices.showSuccessMessage('Quietanza aggiunta con successo');
      await this.onUpdateFasi();
    } else {
      this.scenarioServices.showErrorMessage('Errore durante il salvataggio della quietanza');
      console.log('500');
    }
    this.visibleFormQuietanza = false;
    this.spinner.hide();
  }

  async saveQuietanza(form) {
    this.spinner.show();
    let quietanza = form.value;
    const response = await this.speseServices.updateFase5(quietanza, quietanza.id).toPromise();
    if (response && response.success) {
      this.entityIdAdd = response.dtos?.id;
      this.scenarioServices.showSuccessMessage('Quietanza modificata con successo');
      await this.onUpdateFasi();
    } else {
      this.scenarioServices.showErrorMessage('Errore durante la modifica della quietanza');
      console.log('500');
    }
    this.visibleFormQuietanza = false;
    this.spinner.hide();
  }

  /**
   * Chiusura della modale per upload-file
   */
  closeDialog(event) {
    this.visibleFormSpesa = false;
    this.visibleFormGiustificativo = false;
    this.visibleFormLiquidazione = false;
    this.visibleFormMandato = false;
    this.visibleFormQuietanza = false;
  }

  onChangeItemCurrency(event) {
    if (this.visibleFormSpesa) {
      const spesa = event.formGroup;
      spesa.get('importoNetto').valueChanges.subscribe(() => {
        this.checkImportoNetto();
      });
      spesa.get('importoIVA').valueChanges.subscribe(() => {
        this.checkImportoIva();
      });
      spesa.get('importoCassa').valueChanges.subscribe(() => {
        this.checkImportoCassa();
      });
    }
    this.updateSum();
  }

  checkImportoNetto() {
    let codiceRifImportoNetto = this.datiModelloDocumentale?.formGroup.get('qeItemImportoNettoCompositeId').value;
    if (codiceRifImportoNetto) {
      let rifImportoNetto = this.qeFlatItems.find(x => x.compositeId == codiceRifImportoNetto);
      if (this.datiModelloDocumentale?.formGroup.value.importoNetto > rifImportoNetto.total) {
        this.messageCheckImporti(rifImportoNetto.total, 'importoNetto');
      }
    }
  }

  checkImportoIva() {
    let codiceRifImportoIva = this.datiModelloDocumentale?.formGroup.get('qeItemIVACompositeId').value;
    if (codiceRifImportoIva) {
      let rifImportoIva = this.qeFlatItems.find(x => x.compositeId == codiceRifImportoIva);
      if (this.datiModelloDocumentale?.formGroup.value.importoIVA > rifImportoIva.total) {
        this.messageCheckImporti(rifImportoIva.total, 'importoIVA');
      }
    }
  }

  checkImportoCassa() {
    if (this.datiModelloDocumentale?.formGroup.value.importoIVA) {
      const codiceRifImportoCassa = this.datiModelloDocumentale?.formGroup.get('qeItemCassaCompositeId').value;
      if (codiceRifImportoCassa) {
        let rifImportoCassa = this.qeFlatItems.find(x => x.compositeId == codiceRifImportoCassa);
        if (this.datiModelloDocumentale?.formGroup.value.importoIVA > rifImportoCassa.total) {
          this.messageCheckImporti(rifImportoCassa.total, 'importoCassa');
        }
      }
    }
  }

  messageCheckImporti(total: number, string: string) {
    this.confirmationService.confirm({
      message: `La spesa a cui fai riferimento presenta nel quadro economico
       un importo totale pari a €${total}. <br /> Hai inserito un importo maggiore al totale della spesa di riferimento. Procedere lo stesso?`,
      //message: `Hai inserito un importo maggiore a quello della spesa a cui si fa riferimento, che ammonta ad €${total}. Procedere lo stesso?`,
      header: 'Controllo importi',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si',
      rejectLabel: 'No, torna indietro',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        //this.spesaServices.deleteSpesa(id);
      },
      reject: () => {
        if (string === 'importoNetto') {
          this.datiModelloDocumentale.formGroup.get('importoNetto').setValue(0);
        }
        if (string === 'importoIVA') {
          this.datiModelloDocumentale.formGroup.get('importoIVA').setValue(0);
        }
        if (string === 'importoCassa') {
          this.datiModelloDocumentale.formGroup.get('importoCassa').setValue(0);
        }
      },
    });
  }

  updateSum() {
    if (this.visibleFormSpesa) {
      const importoNetto = this.datiModelloDocumentale.formGroup.get('importoNetto').value;
      const importoIVA = this.datiModelloDocumentale.formGroup.get('importoIVA').value;
      const importoCassa = this.datiModelloDocumentale.formGroup.get('importoCassa').value;
      const sum = importoNetto + importoIVA + importoCassa;
      this.datiModelloDocumentale.formGroup.get('importoTotale').setValue(sum);
    }

    if (this.visibleFormGiustificativo || this.visibleFormLiquidazione) {
      const importoNetto = this.datiModelloDocumentale.formGroup.get('importoNetto').value;
      const importoIVA = this.datiModelloDocumentale.formGroup.get('iva').value;
      const importoCassa = this.datiModelloDocumentale.formGroup.get('cassa').value;
      const sum = importoNetto + importoIVA + importoCassa;
      this.datiModelloDocumentale.formGroup.get('totale').setValue(sum);
    }
  }

  onChangeItemDropdown(event) {
    if (this.visibleFormSpesa) {
      if (event.item === 'qeItemImportoNettoCompositeId') {
        this.onChangeImportoNetto(event.formGroup);
      }
      if (event.item === 'qeItemIVACompositeId') {
        this.onChangeIva(event.formGroup);
      }
      if (event.item === 'qeItemCassaCompositeId') {
        this.onChangeCassa(event.formGroup);
      }
    }
    //!!! SETTA GLI IMPORTI
    if (this.visibleFormLiquidazione) {
      if (event.item === 'fase2Id') {
        //this.onChangeRiferimentoGiustificativo(event.formGroup);
        const riferimentoFind = this.giustificativi.find(
          (rif) => rif.id === event.formGroup.value.fase2Id
        );

        event.formGroup.get('importoNetto').setValue(riferimentoFind.importoNetto);
        event.formGroup.get('iva').setValue(riferimentoFind.iva);
        event.formGroup.get('cassa').setValue(riferimentoFind.cassa);

        if (event.formGroup.value.cassa === 0) {
          //console.log(event.formGroup)
          //event.formGroup.cassa.readonly = true;
          event.formGroup.get('cassa').setValue(null);
          this.datiModelloDocumentale.DatiForm[5].readonly = true;
        }

        this.fase2Riferimenti = new Fase2Riferimenti();
        this.fase2Riferimenti.riferimentoLiquidazione = riferimentoFind.text;
      }
    }
    if (this.visibleFormMandato) {
      console.log(event);
      if (event.item === 'fase3Id') {
        this.onChangeRiferimentoLiquidazione(event.formGroup);
      }
    }
    if (this.visibleFormQuietanza) {
      console.log(event);
      if (event.item === 'fase4Id') {
        this.onChangeRiferimentoMandato(event.formGroup);
      }
    }
  }

  public onChangeRiferimentoMandato(form) {
    const riferimentoFind = this.mandati.find(
      (rif) => rif.id === form.value.fase4Id
    );
    console.log();
    if (riferimentoFind) {
      this.fase2Riferimenti = new Fase2Riferimenti();
      this.fase2Riferimenti.riferimentoLiquidazione = riferimentoFind.text;
      const fase4 = this.spesa.fasi4.find(
        (fase4) => fase4.id === riferimentoFind.id
      );
      if (fase4) {
        const fase3 = this.spesa.fasi3.find(
          (fase3) => fase3.id === fase4.fase3Id
        );
        if (fase3) {
          const fase2 = this.spesa.fasi2.find(
            (fase2) => fase2.id === fase3.fase2Id
          );
          if (fase2) {
            this.fase2Riferimenti.beneficiario = fase2.beneficiario;
            this.fase2Riferimenti.pIvaCodiceFiscale = fase2.pIvaCodiceFiscale;
          }
        }
      }
    }
    this.datiModelloDocumentale.formGroup = form;
  }

  public onChangeRiferimentoLiquidazione(form) {
    const riferimentoFind = this.liquidazioni.find(
      (rif) => rif.id === form.value.fase3Id
    );
    if (riferimentoFind) {
      this.fase2Riferimenti = new Fase2Riferimenti();
      const descrizione = form.get('descrizione').value;
      if (descrizione && descrizione !== '') {
        this.fase2Riferimenti.riferimentoLiquidazione =
          descrizione + ' - ' + riferimentoFind.text;
      } else {
        this.fase2Riferimenti.riferimentoLiquidazione = riferimentoFind.text;
      }

      if (this.spesa.tipo === 'Impegno/Liquidazione') {
        const fase2 = this.spesa.fasi2.find((fase2) => fase2.id === riferimentoFind.id);
        // form.get('fase3Id').setValue(fase2.fasi3[0].id);
        if (fase2) {
          this.fase2Riferimenti.beneficiario = fase2.beneficiario;
          this.fase2Riferimenti.pIvaCodiceFiscale = fase2.pIvaCodiceFiscale;
        }
      } else {
        const fase3 = this.spesa.fasi3.find(
          (fase3) => fase3.id === riferimentoFind.id
        );
        if (fase3) {
          const fase2 = this.spesa.fasi2.find(
            (fase2) => fase2.id === fase3.fase2Id
          );
          if (fase2) {
            this.fase2Riferimenti.beneficiario = fase2.beneficiario;
            this.fase2Riferimenti.pIvaCodiceFiscale = fase2.pIvaCodiceFiscale;
          }
        }
      }
      this.datiModelloDocumentale.formGroup = form;
    }
  }

  onChangeItemTextarea(event) {
    console.log(event);
    if (this.visibleFormMandato) {
      if (event.item === 'descrizione') {
        this.onChangeDescrizione(event.formGroup);
      }
    }
  }

  public onChangeDescrizione(form) {
    if (form.value.descrizione !== '') {
      if (this.fase2Riferimenti && this.fase2Riferimenti.riferimentoLiquidazione) {
        this.fase2Riferimenti.riferimentoLiquidazione = form.value.descrizione + ' - ' + this.fase2Riferimenti.riferimentoLiquidazione;
      }
      this.datiModelloDocumentale.formGroup = form;
    }
  }

  public onChangeRiferimentoGiustificativo(form: FormGroup) {
    console.log(form);
    const riferimentoFind = this.giustificativi.find(
      (rif) => rif.id === form.value.fase2Id
    );
    if (riferimentoFind) {
      this.fase2Riferimenti = new Fase2Riferimenti();
      const fase2 = this.spesa.fasi2.find(
        (fase2) => fase2.id === riferimentoFind.id
      );
      if (fase2) {
        this.fase2Riferimenti.beneficiario = fase2.beneficiario;
        this.fase2Riferimenti.pIvaCodiceFiscale = fase2.pIvaCodiceFiscale;
      }
      this.fase2Riferimenti.riferimentoLiquidazione = riferimentoFind.text;
    }
    this.datiModelloDocumentale.formGroup = form;
  }

  onChangeCassa(form) {
    const items = this.qeFlatItems.filter(
      (x) => x.compositeId == form.value.qeItemCassaCompositeId
    );
    if (items == null || items == undefined) return;
    const flatItem: QEItemBase = items[0];

    const importoCassa = form.get('importoCassa').value;
    if (importoCassa > flatItem.total) {
      this.messageCheckImporti(flatItem.total, 'importoCassa');
    }

    form.get('qeItemCassaIndex').setValue(flatItem.index);
    form.get('qeItemCassaSubIndex').setValue(flatItem.subIndex);
    form.get('qeItemCassaSectionIndex').setValue(flatItem.qeSectionIndex);
    form.get('qeItemCassaSectionQEMainId').setValue(flatItem.qeSectionQEMainId);
    form.get('qeItemCassaCompositeId').setValue(flatItem.compositeId);

    this.datiModelloDocumentale.formGroup = form;
  }

  onChangeIva(form) {
    const items = this.qeFlatItems.filter(
      (x) => x.compositeId == form.value.qeItemIVACompositeId
    );
    if (items == null || items == undefined) return;
    const flatItem: QEItemBase = items[0];

    const importoIVA = form.get('importoIVA').value;
    if (importoIVA > flatItem.total) {
      this.messageCheckImporti(flatItem.total, 'importoIVA');
    }

    form.get('qeItemIVAIndex').setValue(flatItem.index);
    form.get('qeItemIVASubIndex').setValue(flatItem.subIndex);
    form.get('qeItemIVASectionIndex').setValue(flatItem.qeSectionIndex);
    form.get('qeItemIVASectionQEMainId').setValue(flatItem.qeSectionQEMainId);
    form.get('qeItemIVACompositeId').setValue(flatItem.compositeId);

    this.datiModelloDocumentale.formGroup = form;
  }

  /***
   * !!! Alessia TODO: da capire cosa fa! 
   */
  onChangeImportoNetto(form) {
    const items = this.qeFlatItems.filter(
      (x) => x.compositeId == form.value.qeItemImportoNettoCompositeId
    );
    if (items == null || items == undefined) return;
    const flatItem: QEItemBase = items[0];

    const importoNetto = form.get('importoNetto').value;
    if (importoNetto > flatItem.total) {
      this.messageCheckImporti(flatItem.total, 'importoNetto');
    }

    form.get('qeItemImportoNettoIndex').setValue(flatItem.index);
    form.get('qeItemImportoNettoSubIndex').setValue(flatItem.subIndex);
    form.get('qeItemImportoNettoSectionIndex').setValue(flatItem.qeSectionIndex);
    form.get('qeItemImportoNettoSectionQEMainId').setValue(flatItem.qeSectionQEMainId);
    form.get('qeItemCassaCompositeId').setValue(flatItem.qeSectionQEMainId);

    this.datiModelloDocumentale.formGroup = form;
  }

  calcolaRowImpegnate() {
    var rowQEImpegnate = [];
    for (let spesa of this.spese) {
      if (!rowQEImpegnate.includes(JSON.stringify(spesa.qeItemImportoNetto))) {
        rowQEImpegnate.push(JSON.stringify(spesa.qeItemImportoNetto));
      }
      if (!rowQEImpegnate.includes(JSON.stringify(spesa.qeItemIVA))) {
        rowQEImpegnate.push(JSON.stringify(spesa.qeItemIVA));
      }
      if (
        spesa.qeItemCassa != null &&
        !rowQEImpegnate.includes(JSON.stringify(spesa.qeItemCassa))
      ) {
        rowQEImpegnate.push(JSON.stringify(spesa.qeItemCassa));
      }
    }
    this.rowQEImpegnate = [];
    for (let elem of rowQEImpegnate) {
      this.rowQEImpegnate.push(JSON.parse(elem));
    }
    this.sendRowImpegnate.emit(this.rowQEImpegnate);
  }

  async showDialogForm(isInEdit: boolean, visualizza: boolean, spesa?: Fase1) {
    this.spinner.show();
    this.datiModelloDocumentale =
    this.scenarioServices.getModelloDocumentaleCustom(Scenario.Spesa);
    
    const formGroup = this.scenarioServices.getFormGroup(Scenario.Spesa);
    this.datiModelloDocumentale.isFrom = Scenario.Spesa;
    this.datiModelloDocumentale.cup = this.cup;
    this.datiModelloDocumentale.idCup = this.cup?.id;
    this.datiModelloDocumentale.isEdit = isInEdit;
    
    const qeItemImportoNettoCompositeId = this.datiModelloDocumentale.DatiForm.find(x => x.key === 'qeItemImportoNettoCompositeId');
    const qeItemIVACompositeId = this.datiModelloDocumentale.DatiForm.find(x => x.key === 'qeItemIVACompositeId');
    const qeItemCassaCompositeId = this.datiModelloDocumentale.DatiForm.find(x => x.key === 'qeItemCassaCompositeId');
    if(this.qeFlatItems?.length === 0){
      await this.getQeFlatItems();
    } 
    qeItemImportoNettoCompositeId.lista = this.qeFlatItems;
    qeItemIVACompositeId.lista = this.qeFlatItems;
    qeItemCassaCompositeId.lista = this.qeFlatItems;

    if (isInEdit) {
      this.datiModelloDocumentale.entityID = spesa.id;
      formGroup.setValue({
        cig: spesa.cig || null,
        id: spesa.id,
        tipo: spesa.tipo,
        atto: spesa.atto,
        numero: spesa.numero,
        beneficiario: spesa.beneficiario,
        data: new Date(spesa.data),
        descrizione: spesa.descrizione,
        pIvaCodiceFiscale: spesa.pIvaCodiceFiscale,
        importoNetto: spesa.importoNetto,
        importoIVA: spesa.importoIVA,
        importoCassa: spesa.importoCassa,
        qeItemImportoNettoIndex: spesa.qeItemImportoNetto?.index ?? null,
        qeItemImportoNettoCompositeId:
          spesa.qeItemImportoNetto?.compositeId ?? null,
        qeItemImportoNettoSubIndex: spesa.qeItemImportoNetto?.subIndex ?? null,
        qeItemImportoNettoSectionIndex:
          spesa.qeItemImportoNetto?.qeSectionIndex ?? null,
        qeItemImportoNettoSectionQEMainId:
          spesa.qeItemImportoNetto?.qeSectionQEMainId ?? null,
        qeItemIVACompositeId: spesa.qeItemIVA?.compositeId ?? null,
        qeItemIVAIndex: spesa.qeItemIVA?.index ?? null,
        qeItemIVASubIndex: spesa.qeItemIVA?.subIndex ?? null,
        qeItemIVASectionIndex: spesa.qeItemIVA?.qeSectionIndex ?? null,
        qeItemIVASectionQEMainId: spesa.qeItemIVA?.qeSectionQEMainId ?? null,
        qeItemCassaIndex: spesa.qeItemCassa?.index ?? null,
        qeItemCassaCompositeId: spesa.qeItemCassa?.compositeId ?? null,
        qeItemCassaSubIndex: spesa.qeItemCassa?.subIndex ?? null,
        qeItemCassaSectionIndex: spesa.qeItemCassa?.qeSectionIndex ?? null,
        qeItemCassaSectionQEMainId:
          spesa.qeItemCassa?.qeSectionQEMainId ?? null,
        cupId: this.idCup,
        importoTotale:
          spesa.importoNetto + spesa.importoIVA + spesa.importoCassa,
      });
      formGroup.disable();
    }

    this.datiModelloDocumentale.DatiForm[1].lista =
      this.tipiSpeseFromDizionario;
    this.datiModelloDocumentale.DatiForm[2].lista =
      this.tipiAttiFromDizionario;
    this.datiModelloDocumentale.formGroup = formGroup;
    this.datiModelloDocumentale.entityTipo = this.scenarioServices.getTipologia(
      'Ordinativo di spesa',
      formGroup
    );
    this.spinner.hide();
    this.visualizza = visualizza;
    this.visibleFormSpesa = true;
  }

  showDialogFatture(
    isInEdit: boolean,
    visualizza: boolean,
    giustificativo: Fase2,
    spesa?: Fase1,
    isFromCartellaMonitorata = false
  ) {
    this.spinner.show();
    this.datiModelloDocumentale =
      this.scenarioServices.getModelloDocumentaleCustom(Scenario.Giustificativo);
    this.datiModelloDocumentale.isEdit = isInEdit;
    this.datiModelloDocumentale.cup = this.cup;
    this.datiModelloDocumentale.idCup = this.cup?.id;

    if (!isFromCartellaMonitorata) {
      this.sharedService.setAnteprimaDocumentoCartellaMonitorata(
        false,
        undefined
      );
    }

    const formGroup = this.scenarioServices.getFormGroup(Scenario.Giustificativo);
    this.datiModelloDocumentale.DatiForm[0].lista = this.tipiGiustificativiFromDizionario;

    if (isInEdit) {
      this.datiModelloDocumentale.entityID = giustificativo.id;
      formGroup.setValue({
        id: giustificativo.id,
        fase1Id: giustificativo.fase1Id,
        tipo: giustificativo.tipo,
        numero: giustificativo.numero,
        data: new Date(giustificativo.data),
        importoNetto: giustificativo.importoNetto,
        iva: giustificativo.iva,
        cassa: giustificativo.cassa,
        descrizione: giustificativo.descrizione || null,
        beneficiario: giustificativo.beneficiario || null,
        pIvaCodiceFiscale: giustificativo.pIvaCodiceFiscale || null,
        totale:
          giustificativo.importoNetto +
          giustificativo.iva +
          giustificativo.cassa,
      });

      if (spesa && spesa.pIvaCodiceFiscale === '11111111111') {
        this.datiModelloDocumentale.DatiForm[5].readonly = false;
        this.datiModelloDocumentale.DatiForm[6].readonly = false;
      }
      formGroup.disable();
    } else {
      formGroup.get('id').setValue(0);
      formGroup.get('fase1Id').setValue(spesa.id);

      if (spesa && spesa.pIvaCodiceFiscale === '11111111111') {
        this.datiModelloDocumentale.DatiForm[5].readonly = false;
        this.datiModelloDocumentale.DatiForm[6].readonly = false;
      } else {
        formGroup.get('beneficiario').setValue(spesa.beneficiario);
        formGroup.get('pIvaCodiceFiscale').setValue(spesa.pIvaCodiceFiscale);

        this.datiModelloDocumentale.DatiForm[5].readonly = true;
        this.datiModelloDocumentale.DatiForm[6].readonly = true;
      }
    }

    this.datiModelloDocumentale.formGroup = formGroup;
    this.datiModelloDocumentale.entityTipo = this.scenarioServices.getTipologia(
      'Giustificativo di spesa',
      formGroup
    );
    this.spinner.hide();
    this.visualizza = visualizza;
    this.visibleFormGiustificativo = true;
  }

  async showDialogLiquidazioni(
    isInEdit: boolean,
    visualizza: boolean,
    spesa: Fase1,
    liquidazione?: Fase3,
    isFromCartellaMonitorata = false
  ) {
    this.spinner.show();
    this.datiModelloDocumentale = this.scenarioServices.getModelloDocumentaleCustom(Scenario.Liquidazione);
    this.datiModelloDocumentale.isEdit = isInEdit;
    this.datiModelloDocumentale.cup = this.cup;
    this.datiModelloDocumentale.idCup = this.cup?.id;

    if (!isFromCartellaMonitorata) {
      this.sharedService.setAnteprimaDocumentoCartellaMonitorata(false, undefined);
    }

    this.spesa = spesa;
    this.beneficiarioString = spesa.beneficiario;
    this.pIva_cFiscaleString = spesa.pIvaCodiceFiscale;

    const formGroup = this.scenarioServices.getFormGroup(Scenario.Liquidazione);
    const response = await this.speseServices.rifFase2(spesa.id).toPromise();
    if (response && response.success) {
      this.giustificativi = response.dtos;
    }

    if (isInEdit) {
      this.datiModelloDocumentale.entityID = liquidazione.id;
      formGroup.setValue({
        id: liquidazione.id,
        fase2Id: liquidazione.fase2Id,
        numero: liquidazione.numero,
        data: new Date(liquidazione.data),
        importoNetto: liquidazione.importoNetto,
        iva: liquidazione.iva,
        cassa: liquidazione.cassa,
        totale:
          liquidazione.importoNetto + liquidazione.iva + liquidazione.cassa,
      });
      formGroup.disable();
    } else {
      formGroup.get('id').setValue(0);
    }

    this.visualizza = visualizza;
    this.visualizzaForm = this.giustificativi.length > 0;
    this.datiModelloDocumentale.DatiForm[2].lista = this.giustificativi;

    this.datiModelloDocumentale.formGroup = formGroup;
    this.datiModelloDocumentale.entityTipo = this.scenarioServices.getTipologia('Liquidazione', formGroup);
    this.spinner.hide();
    this.visibleFormLiquidazione = true;
  }

  async showDialogMandati(isInEdit: boolean, visualizza: boolean, spesa: Fase1, mandato?: Fase4, isFromCartellaMonitorata = false) {
    this.spinner.show();
    this.datiModelloDocumentale =
      this.scenarioServices.getModelloDocumentaleCustom(Scenario.Mandato);
    this.datiModelloDocumentale.isEdit = isInEdit;
    this.spesa = spesa;

    if (!isFromCartellaMonitorata) {
      this.sharedService.setAnteprimaDocumentoCartellaMonitorata(
        false,
        undefined
      );
    }

    this.beneficiarioString = spesa.beneficiario;
    this.pIva_cFiscaleString = spesa.pIvaCodiceFiscale;
    let fase3Id = mandato ? mandato.fase3Id : null;
    const formGroup = this.scenarioServices.getFormGroup(Scenario.Mandato);
    if (this.spesa.tipo === 'Impegno/Liquidazione') {
      const response = await this.speseServices.rifFase2(spesa.id).toPromise();
      if (response && response.success) {
        this.liquidazioni = response.dtos;
      }
    } else {
      const response = await this.speseServices.rifFase3(spesa.id).toPromise();
      if (response && response.success) {
        this.liquidazioni = response.dtos;
      }
    }
    
    this.visualizzaForm = this.liquidazioni?.length > 0;
    this.datiModelloDocumentale.DatiForm[4].lista = this.liquidazioni;
    if (isInEdit) {
      if (this.liquidazioni && this.liquidazioni.length > 0 && this.spesa.tipo === 'Impegno/Liquidazione') {
        const fase3 = this.spesa.fasi3.find(fase3 => fase3.id === mandato.fase3Id);
        if (fase3) {
          const fase2 = this.spesa.fasi2.find(fase2 => fase2.id === fase3.fase2Id);
          if (fase2) {
            fase3Id = fase2.id;
          }
        } 
      }
      this.datiModelloDocumentale.entityID = mandato.id;
      formGroup.setValue({
        id: mandato.id,
        fase3Id: mandato.fase3Id === fase3Id ? mandato.fase3Id : fase3Id,
        numero: mandato.numero,
        data: new Date(mandato.data),
        importo: mandato.importo,
        descrizione: mandato.descrizione,
      });
      formGroup.disable();
    } else {
      formGroup.get('id').setValue(0);
    }

    this.datiModelloDocumentale.cup = this.cup;
    this.datiModelloDocumentale.idCup = this.cup?.id;
    this.datiModelloDocumentale.formGroup = formGroup;
    this.datiModelloDocumentale.entityTipo = this.scenarioServices.getTipologia('Mandato',formGroup);
    this.spinner.hide();
    this.visualizza = visualizza;
    this.visibleFormMandato = true;
  }

  public getQuietanze(spesa: Fase1, mandato: Fase4){
    return spesa.fasi5.filter(quietanze => quietanze.fase4Id === mandato.id);;
  }

  async showDialogQuietanze(
    isInEdit: boolean,
    spesa: Fase1,
    quietanza?: Fase5,
    isFromCartellaMonitorata = false
  ) {
    this.spinner.show();
    this.datiModelloDocumentale = this.scenarioServices.getModelloDocumentaleCustom(Scenario.Quietanza);
    this.datiModelloDocumentale.isEdit = isInEdit;


    this.spesa = spesa;
    this.quietanza = quietanza;

    if (!isFromCartellaMonitorata) {
      this.sharedService.setAnteprimaDocumentoCartellaMonitorata(
        false,
        undefined
      );
    }

    this.beneficiarioString = spesa.beneficiario;
    this.pIva_cFiscaleString = spesa.pIvaCodiceFiscale;

    const formGroup = this.scenarioServices.getFormGroup(Scenario.Quietanza);
    const response = await this.speseServices.rifFase4(spesa.id).toPromise();
    if (response && response.success) {
      this.mandati = response.dtos;
    } else {
      console.log('500');
    }
    this.datiModelloDocumentale.DatiForm[0].lista = this.mandati || [];

    if (this.mandati.length > 0 && this.mandati.length === spesa.fasi5.length) {
      this.visualizzaForm = false;
      this.stopFase5 = true;
    } else {
      this.visualizzaForm = this.mandati.length === 0;
    }

    if (this.quietanza) {
      var mandatoRif = this.mandati.filter(
        (x) => x.id === this.quietanza.fase4Id
      );
    }
    for (let quietanza of spesa.fasi5) {
      for (let mandato of this.mandati) {
        if (quietanza.fase4Id === mandato.id) {
          this.mandati = this.mandati.filter((x) => x.id !== mandato.id);
        }
      }
    }

    if (this.mandati.length === 0 && this.isInEdit) {
      this.mandati = mandatoRif;
    }


    if (isInEdit) {
      this.datiModelloDocumentale.entityID = quietanza.id;
      formGroup.setValue({
        id: quietanza.id,
        fase4Id: quietanza.fase4Id,
        attachment: quietanza.attachmentUrl,
      });
      formGroup.disable();
    } else {
      formGroup.get('id').setValue(0);
    }

    this.datiModelloDocumentale.entityTipo = this.scenarioServices.getTipologia(
      'Quietanza',
      formGroup
    );
    this.spinner.hide();
    this.datiModelloDocumentale.cup = this.cup;
    this.datiModelloDocumentale.idCup = this.cup?.id;
    this.datiModelloDocumentale.formGroup = formGroup;
    this.visibleFormQuietanza = true;
  }

  confirm(id) {
    this.confirmationService.confirm({
      message: 'Sei sicuro di voler eliminare questa spesa?',
      header: 'Eliminazione',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, elimina',
      rejectLabel: 'No, torna indietro',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.speseServices.deleteFase1(id).subscribe({
          next: async (resp) => {
            if (resp) {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Spesa eliminata',
              });
              //this.filteredTab = this.spese.filter(x=> x.id !== id)
              await this.onUpdateFasi();
            }
          },
        });
      },
      reject: () => { },
    });
  }
  confirm2(id) {
    this.confirmationService.confirm({
      message: 'Sei sicuro di voler eliminare questo giustificativo di spesa?',
      header: 'Eliminazione',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, elimina',
      rejectLabel: 'No, torna indietro',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.speseServices.deleteFase2(id).subscribe({
          next: async (resp) => {
            if (resp) {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Giustificativo eliminato',
              });
              //this.filteredTab = this.spese.filter(x=> x.id !== id)
              await this.onUpdateFasi();
            }
          },
        });
      },
      reject: () => { },
    });
  }
  confirm3(id) {
    this.confirmationService.confirm({
      message: 'Sei sicuro di voler eliminare questa liquidazione?',
      header: 'Eliminazione',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, elimina',
      rejectLabel: 'No, torna indietro',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.speseServices.deleteFase3(id).subscribe({
          next: async (resp) => {
            if (resp) {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Liquidazione eliminata',
              });
              //this.filteredTab = this.spese.filter(x=> x.id !== id)
              await this.onUpdateFasi();
            }
          },
        });
      },
      reject: () => { },
    });
  }

  confirm4(id) {
    this.confirmationService.confirm({
      message: 'Sei sicuro di voler eliminare questo mandato?',
      header: 'Eliminazione',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, elimina',
      rejectLabel: 'No, torna indietro',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.speseServices.deleteFase4(id).subscribe({
          next: async (resp) => {
            if (resp) {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Manadto eliminato',
              });
              //this.filteredTab = this.spese.filter(x=> x.id !== id)
              await this.onUpdateFasi();
            }
          },
        });
      },
      reject: () => { },
    });
  }
  confirm5(id) {
    this.confirmationService.confirm({
      message: 'Sei sicuro di voler eliminare questa quietanza?',
      header: 'Eliminazione',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, elimina',
      rejectLabel: 'No, torna indietro',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {

        this.speseServices.deleteFase5(id).subscribe({
          next: async (resp) => {
            if (resp) {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Quietanza eliminata',
              });
              await this.onUpdateFasi(true);
            }
          },
        });
      },
      reject: () => { },
    });
  }

  /* STYLE GRIGLIA */
  mandatoToString(spesa, id: number) {
    this.rif = spesa.fasi4.filter((x) => x.id === id);
    return `Atto ${this.rif[0].numero}`;
  }

  resizeTextarea() {
    const textarea = document.getElementById('myTextarea');
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  /* FILTRI TAB ESECUZIONE */
  filter(tipo: string) {
    if (tipo === 'Tutti') {
      this.filteredTab = this.spese;
    } else {
      this.filteredTab = this.spese.filter((item) => item.tipo == tipo);
    }
  }

  filterRiferimento(riferimento: string) {
    if (riferimento) {
      let itemRif = this.qeFlatItems.find((x) => x.title === riferimento);
      this.filteredTab = this.spese.filter(
        (x) =>
          x.qeItemImportoNetto?.title === itemRif.item ||
          x.qeItemIVA?.title === itemRif.item ||
          x.qeItemCassa?.title === itemRif.item
      );
    } else {
      this.filteredTab = this.spese;
    }
  }

  filterBeneficiario(beneficiario) {
    if (beneficiario) {
      this.filteredTab = this.spese.filter(
        (x) => x.beneficiario === beneficiario.nome
      );
    } else {
      this.filteredTab = this.spese;
    }
  }

  /**
   * Al completamento di ogni modifica all'interni dell'esecuzione, 
   * qst metodo assicura la'ggiornamento della sezione
   */
  async onUpdateFasi(addSpinner: boolean = false) {
    let retVal = false;
    delete this.beneficiari;
    const idCup = this.cup?.id || this.idCup;
    this.sharedService.deleteRowsImpegnate();
    const response = await this.speseServices.getAllFasiByCupId(idCup).toPromise();
    if (response?.success) {
      this.spese = response.dtos;
      this.calcolaRowImpegnate();
      retVal = true;
      this.beneficiari = this.getBeneficiariFiltro(this.spese);      
      this.spese.forEach(fase1 => {
        fase1.fasi3 = fase1.fasi2?.flatMap(fase2 => fase2.fasi3 || []);
        fase1.fasi4 = fase1.fasi3.flatMap(fase3 => fase3.fasi4 || []);
        fase1.fasi5 = fase1.fasi4.flatMap(fase4 => fase4.fase5 || []);
      });
      this.filteredTab = this.spese;
    } else {
      this.scenarioServices.showErrorMessage('Errore recupero Spese');
    }
    if (addSpinner) {
      this.spinner.hide();
    }
    return retVal;
  }


  private getBeneficiariFiltro(spese) {
    return spese.map((spesa:Fase1) => {
      return { nome: spesa.beneficiario };
    });
  }



}
//this.calcolaTotale = this.fatture.map(t => t.totale).reduce((acc, value) => acc + value, 0);
